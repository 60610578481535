import "./bootstrap"
import "../css/app.css"

import { createApp, h } from "vue"
import { createInertiaApp } from "@inertiajs/vue3"
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers"
import { ZiggyVue } from "../../vendor/tightenco/ziggy"
import * as Sentry from "@sentry/vue"
import LaravelPermissionToVueJS from "../../vendor/zodexnl/spatie-permission-to-vue-inertia/src/js"
import "../../node_modules/flowbite-vue/dist/index.css"
import FlashMessage from "./Components/FlashMessage.vue"
import "../../node_modules/vue-multiselect/dist/vue-multiselect.css"
import VueTailwindDatepicker from 'vue-tailwind-datepicker'

const appName = import.meta.env.VITE_APP_NAME || "Laravel"

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob("./Pages/**/*.vue")),
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) })
        // Sentry.init({
        //   app,
        //   dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
        //   environment: import.meta.env.APP_ENV,
        //   integrations: [
        //     // Sentry.browserTracingIntegration({ router }),
        //     Sentry.replayIntegration({
        //       maskAllText: true,
        //       blockAllMedia: true,
        //     }),
        //   ],
        //   logErrors: true,
        //   replaysSessionSampleRate: 0.1,
        //   replaysOnErrorSampleRate: 1.0,
        // })

        // console.log(app.config)
        app.use(plugin).use(ZiggyVue).use(LaravelPermissionToVueJS).component("flash-message", FlashMessage).mount(el)
        app.use(VueTailwindDatepicker)
        return app
    },
    progress: {
        color: "#750509",
        showSpinner: true,
    },
})
